import React, { useEffect, useState } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons'
import tw from 'twin.macro'
import Button from '../Button'

export const Label = tw.label`block font-bold text-gray-700 leading-4`

const InputField = tw.input`text-base block w-full border border-gray-700 p-4 mb-3  focus:(border-blue-600)`

export const Input = props => <InputField type="text" {...props} />
export const Email = props => (
  <InputField
    type="email"
    {...props}
    autocomplete="email"
    inputProps={{ inputMode: 'email' }}
    tw="max-w-sm"
  />
)
export const Phone = props => (
  <InputField
    type="tel"
    {...props}
    autocomplete="tel"
    inputProps={{ inputMode: 'tel' }}
    minLength={10}
    tw="max-w-sm"
  />
)
export const Number = props => (
  <InputField type="number" {...props} inputProps={{ inputMode: 'numeric' }} />
)

export const Submit = ({ label, loading, submitted, ...remainingProps }) => (
  <Button
    tw="w-full sm:(w-auto)"
    type="submit"
    {...remainingProps}
    disabled={loading || submitted}
    aria-label={label}
  >
    {submitted ? 'Thank You' : label}
    {!loading ? (
      <FontAwesomeIcon
        icon={faChevronRight}
        tw="ml-4 hidden sm:(inline-block)"
      />
    ) : null}
    {loading ? (
      <FontAwesomeIcon
        icon={faSpinner}
        tw="ml-2 w-4 text-yellow-800 animate-spin"
      />
    ) : null}
  </Button>
)

export const Textarea = tw.textarea`text-base block w-full border border-gray-700 p-4 mb-3 focus:(border-blue-500) sm:(text-sm) min-h-50`

export const Select = tw.select`block w-full border border-gray-700 px-3 text-base mb-8 h-12 py-1 focus:(border-blue-500) sm:(text-sm)`

export const SelectField = ({ data, onChange, dispatch, id }) => {
  const choices = JSON.parse(data.choices)

  // auto select the first choice
  useEffect(() => {
    dispatch({
      type: 'updateField',
      key: `input_${data.id}`,
      value: choices[0].text,
    })
  }, [])

  return (
    <Select onChange={onChange} id={id} tw="max-w-lg sm:(max-w-xs)">
      {choices.map(choice => (
        <option value={choice.id}>{choice.text} </option>
      ))}
    </Select>
  )
}

export const Checkbox = ({ name, label, ...remainingProps }) => {
  const [checked, setChecked] = useState(false)
  return (
    <div tw="flex content-center justify-start">
      <div tw="relative block cursor-pointer mr-6 sm:(mr-8)">
        <input
          id={name}
          type="checkbox"
          onClick={e => setChecked(e.target.checked)}
          css={[
            tw`block absolute transform scale-150 opacity-0 focus:(border-blue-500) h-4 w-4 mr-2 text-blue-600 border-gray-300 z-10`,
            `left: .125rem;
            top: .125rem;`,
            // 'flex: 0 0 1em;',
          ]}
          value={label}
          {...remainingProps}
        />
        <span
          css={[
            tw`absolute flex justify-center content-center top-0 left-0 h-5 w-5 bg-white border-2 border-blue-500 rounded z-0`,
            checked && tw`bg-blue-500`,
            tw`
              hover:(bg-blue-200)`,
          ]}
        >
          {checked && <FontAwesomeIcon tw="text-white" icon={faCheck} />}
        </span>
      </div>
      <Label
        htmlFor={name}
        css={[
          tw`inline-block font-semibold text-xs pt-1 sm:(text-sm font-bold)`,
        ]}
      >
        {label}
      </Label>
    </div>
  )
}

export const CheckboxField = ({ data, id, dispatch, ...remainingProps }) => {
  const choices = JSON.parse(data.choices)

  return (
    <div tw="mt-4 grid-cols-2 grid gap-3">
      {choices.map((choice, i) => (
        <Checkbox
          tw="mb-8"
          key={`${choice.text}-${i}`}
          name={`${id}_${i + 1}`}
          label={choice.text}
          htmlFor={choice.label}
          aria-label={choice.label}
          value={choice.text}
          group={data.id}
          onChange={e => {
            if (!e.target.checked) {
              dispatch({ type: 'clearField', key: `input_${id}_${i + 1}` })
            } else {
              dispatch({
                type: 'updateField',
                key: `input_${id}_${i + 1}`,
                value: choice.text,
              })
            }
          }}
        />
      ))}
    </div>
  )
}

export const Radio = ({
  id,
  value,
  name,
  label,
  group,
  dispatch,
  css,
  onSelect,
  selectedRadio,
  ...remainingProps
}) => {
  return (
    <div css={[`${css}`, tw`mb-5`]} {...remainingProps}>
      <Label
        htmlFor={id ?? name}
        aria-label={label}
        css={[tw`inline-block font-normal text-xl pt-2`]}
      >
        <div tw="inline-block relative mr-10 -mt-4">
          <input
            id={id ?? name}
            name={group}
            type="radio"
            css={[
              tw`absolute top-0 ml-1 transform scale-150 opacity-0 -mt-4 focus:(border-blue-500) h-4 w-4 mr-2 text-blue-600 border-gray-300 z-20`,
            ]}
            value={value || name}
            onChange={e => {
              if (typeof onSelect === 'function') onSelect()
              if (typeof dispatch === 'function') {
                dispatch({
                  type: 'updateField',
                  key: `input_${id.split('-')[0]}`,
                  value: e.target.value,
                })
              }
            }}
          />
          <span
            css={[
              tw`absolute rounded-full h-6 w-6 -mt-5 top-0 left-0 bg-white border-4 border-blue-400 z-0 flex justify-center content-center`,
            ]}
          >
            {selectedRadio && (
              <span tw="bg-blue-500 rounded-full w-2 h-2 mt-1 z-10" />
            )}
          </span>
        </div>

        {label}
        {/* {JSON.stringify({checked})} */}
      </Label>
    </div>
  )
}

export const RadioField = ({
  data,
  dispatch,
  column,
  id,
  callback,
  ...remainingProps
}) => {
  const choices =
    typeof data.choices === 'string' ? JSON.parse(data.choices) : data.choices

  const [selectedRadio, setSelectedRadio] = useState(undefined)

  return (
    <div
      css={[
        tw`mb-5 flex flex-wrap justify-start content-center col-gap-4`,

        column && tw`inline-flex text-xs content-start`,
      ]}
    >
      {choices.map((choice, index) => (
        <Radio
          // * ios does not support flex column gap
          // use sibling selector
          css={[!column && index > 0 && tw`-ml-1`]}
          key={choice.text}
          name={choice.text}
          label={choice?.label || choice.text}
          value={choice.text}
          group={data.id}
          checked={choice?.checked || selectedRadio === choice.text}
          id={`${choice?.id ?? id}-${index}`}
          selectedRadio={selectedRadio === choice.text || choice.checked}
          onSelect={e => {
            setSelectedRadio(choice.text)
            typeof callback === 'function' && callback(choice.text)
          }}
          dispatch={dispatch}
          {...remainingProps}
        />
      ))}
    </div>
  )
}

export const Switch = ({ checked, alt, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-pressed={checked}
      css={[
        tw`bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-2 focus:border-blue-500`,
        checked && tw`bg-blue-600`,
      ]}
    >
      <span css={[tw`sr-only`]}>{alt}</span>
      <span
        aria-hidden="true"
        css={[
          tw`translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform border-none transition ease-in-out duration-200`,
          checked && tw`translate-x-5`,
        ]}
      ></span>
    </button>
  )
}

export const SectionField = ({ data }) => (
  <legend tw="text-base border-b pb-2 md:(text-2xl) font-bold">
    {data.label} <span tw="text-red-600">*</span>
  </legend>
)

export const HiddenField = ({ data, onChange, id, dispatch }) => {
  // auto select the first choice
  useEffect(() => {
    dispatch({
      type: 'updateField',
      key: `input_${data.id}`,
      value: data.defaultValue,
    })
  }, [])
  return (
    <input
      id={data.id}
      value={data.defaultValue}
      aria-label={data.label}
      type="hidden"
      onChange={onChange}
    />
  )
}

export default {
  Label,
  Input,
  Email,
  Phone,
  Number,
  Submit,
  Textarea,
  Select,
  SelectField,
  Checkbox,
  CheckboxField,
  Radio,
  RadioField,
  Switch,
  SectionField,
  HiddenField,
}
